import Bottombar from "./Bottombar/Bottombar";
import Navbar from "./Navbar/Navbar";

const PageLayout = ({ children }) => {
  return (
    <div className="pb-[80px] md:pb-[90px] min-h-screen w-full flex flex-col">
      <Navbar />
      {children}
      <Bottombar />
    </div>
  );
};

export default PageLayout;
