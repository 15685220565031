import axios from "../../axios";

const loginWithPassword = async (data) => {
  const response = await axios({
    method: "post",
    url: `/api/user/login`,
    data,
  });
  return response.data;
};

const signUpWithEmail = async (values) => {
  const response = await axios({
    method: "post",
    url: `/api/user/signup`,
    data: values,
  });
  return response.data;
};

const sendOtp = async (data) => {
  const response = await axios({
    method: "post",
    url: `/api/user/otp/send`,
    data,
  });
  return response.data;
};

const resendOtp = async (data) => {
  const response = await axios({
    method: "post",
    url: `/api/user/otp/resend`,
    data,
  });
  return response.data;
};

const verifyOtp = async (data) => {
  const response = await axios({
    method: "post",
    url: `/api/user/otp/verify`,
    data,
  });
  return response.data;
};

const logout = async () => {
  const response = await axios({
    method: "delete",
    url: `/api/user/logout`,
  });
  return response.data;
};

const forgotPassword = async (data) => {
  const response = await axios({
    method: "post",
    url: `/api/user/forgot-password`,
    data,
  });
  return response.data;
};

const resetPassword = async (data) => {
  const response = await axios({
    method: "post",
    url: `/api/user/change-password`,
    data,
  });
  return response.data;
};

const checkUserExists = async (data) => {
  const response = await axios({
    method: "post",
    url: `/api/user/check`,
    data,
  });
  return response.data;
};

const loginWithOtp = async (data) => {
  const response = await axios({
    method: "post",
    url: `/api/user/login-with-otp`,
    data,
  });
  return response.data;
};

const checkUserActive = async () => {
  const response = await axios({
    method: "get",
    url: `/api/user/active`,
  });
  return response.data;
};

export {
  checkUserActive,
  checkUserExists,
  forgotPassword,
  loginWithOtp,
  loginWithPassword,
  logout,
  resendOtp,
  resetPassword,
  sendOtp,
  signUpWithEmail,
  verifyOtp
};

