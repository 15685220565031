import React, { useEffect, useState } from "react";
import { BiCheck, BiSearch, BiUser } from "react-icons/bi";
import { Modal } from "react-responsive-modal";
import { uid } from "react-uid";
import { getAllQuizmasters, joinQuizmaster } from "../../../apis/user/user";
import defaultAvatar from "../../../assets/images/quizManage.png";
import { useAlertModal } from "../../../hooks/useAlertModal";

const JoinQuizMasterModal = ({ isOpen, onClose, refetch }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedQuizmaster, setSelectedQuizmaster] = useState(null);
  const [isSearching, setIsSearching] = useState(false);

  const handleClose = () => {
    onClose();
    setSearchQuery("");
    setSelectedQuizmaster(null);
    setIsSearching(false);
    setSearchResults([]);
  };
  const { showAlert, AlertModalComponent } = useAlertModal();

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (searchQuery.trim().length < 3) {
        setSearchResults([]);
        return;
      }

      setIsSearching(true);
      try {
        const response = await getAllQuizmasters({
          searchQuery,
          includeDefault: "false",
          active: true,
          limit: 10,
        });
        if (response?.status === "success") {
          setSearchResults(response.data.quizmasters);
        } else {
          setSearchResults([]);
        }
      } catch (error) {
        setSearchResults([]);
      } finally {
        setIsSearching(false);
      }
    };

    const timeoutId = setTimeout(fetchSearchResults, 300);
    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  const handleJoin = async () => {
    if (!selectedQuizmaster) {
      showAlert("Please select a Quizmaster from the list.");
      return;
    }

    try {
      const response = await joinQuizmaster(selectedQuizmaster.code);

      if (response?.status === "success") {
        if (response.data.autoAccept) {
          showAlert("Successfully joined the Quizmaster!");
        } else {
          showAlert("Request sent to Quizmaster successfully!");
        }
        handleClose();
        refetch();
      }
    } catch (error) {
      showAlert("You have already joined this Quiz Master");
    }
  };

  return (
    <>
      <Modal
        focusTrapped={false}
        open={isOpen}
        onClose={handleClose}
        closeIcon={closeIcon}
        classNames={{
          modal: "joinquizmasterModal rounded-lg",
          overlay: "!bg-black/50",
        }}
        center
      >
        <div className="relative flex flex-col h-full w-full">
          <div className="flex flex-col w-full">
            <h2 className="py-3 bg-[#760A98] text-white text-lg font-medium text-center w-full rounded-t-lg">
              Search Quiz Masters
            </h2>

            <div className="px-6 pt-6 pb-4">
              <div className="relative">
                <BiSearch className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 w-5 h-5" />
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search by name or code..."
                  className="w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#760A98] text-gray-700"
                />
              </div>
            </div>

            <div className="px-6 max-h-[300px] overflow-y-auto">
              {isSearching ? (
                <div className="flex items-center justify-center py-8 text-gray-500">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#760A98]"></div>
                </div>
              ) : searchResults.length > 0 ? (
                <div className="space-y-2">
                  {searchResults.map((quizmaster) => (
                    <div
                      key={uid(quizmaster)}
                      onClick={() => setSelectedQuizmaster(quizmaster)}
                      className={`relative p-3 rounded-lg cursor-pointer transition-all duration-200 hover:bg-gray-50 border ${
                        selectedQuizmaster?.id === quizmaster.id
                          ? "border-[#760A98] bg-purple-50"
                          : "border-gray-200"
                      }`}
                      style={{ transition: "box-shadow 0.2s, transform 0.2s" }}
                    >
                      <div className="flex items-center gap-4">
                        <div>
                          <img
                            src={quizmaster.profileImage || defaultAvatar}
                            alt={quizmaster.name}
                            className="w-12 h-12 rounded-full object-cover border-2 border-gray-200"
                          />
                        </div>
                        <div className="flex-grow min-w-0">
                          <h3 className="font-medium text-gray-900 truncate">
                            {quizmaster.name}
                          </h3>
                          <p className="text-sm text-gray-500">
                            Code: {quizmaster.code}
                          </p>
                        </div>
                        {selectedQuizmaster?._id === quizmaster._id && (
                          <div className="flex-shrink-0">
                            <BiCheck className="w-6 h-6 text-[#760A98]" />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                searchQuery.trim() !== "" && (
                  <div className="text-center">
                    <div className="bg-gray-50 rounded-lg p-6">
                      <BiUser className="w-12 h-12 text-gray-400 mx-auto mb-2" />
                      <p className="text-gray-500">No quizmasters found.</p>
                      <p className="text-sm text-gray-400 mt-1">
                        Try a different search term
                      </p>
                    </div>
                  </div>
                )
              )}
            </div>

            <div className="mt-6 px-6 pb-6 flex justify-end space-x-3">
              <button
                onClick={handleJoin}
                disabled={!selectedQuizmaster}
                className={`px-6 py-2 rounded-lg transition-colors ${
                  selectedQuizmaster
                    ? "bg-green-500 hover:green-600 text-white"
                    : "bg-gray-200 text-gray-500 cursor-not-allowed"
                }`}
              >
                Add
              </button>
              <button
                onClick={handleClose}
                className="px-4 py-2 text-white bg-red-500 hover:bg-red-600 rounded-lg hover:bg-gray-200 transition-colors"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {AlertModalComponent}
    </>
  );
};

const closeIcon = (
  <svg width="33px" height="33px" viewBox="0 0 24 24" fill="#FFAEC4">
    <path
      d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2Z"
      fill="white"
    />
    <path
      d="M13.0594 12.0001L15.3594 9.70011C15.6494 9.41011 15.6494 8.93011 15.3594 8.64011C15.0694 8.35011 14.5894 8.35011 14.2994 8.64011L11.9994 10.9401L9.69937 8.64011C9.40937 8.35011 8.92937 8.35011 8.63938 8.64011C8.34938 8.93011 8.34938 9.41011 8.63938 9.70011L10.9394 12.0001L8.63938 14.3001C8.34938 14.5901 8.34938 15.0701 8.63938 15.3601C8.78938 15.5101 8.97937 15.5801 9.16937 15.5801C9.35937 15.5801 9.54937 15.5101 9.69937 15.3601L11.9994 13.0601L14.2994 15.3601C14.4494 15.5101 14.6394 15.5801 14.8294 15.5801C15.0194 15.5801 15.2094 15.5101 15.3594 15.3601C15.6494 15.0701 15.6494 14.5901 15.3594 14.3001L13.0594 12.0001Z"
      fill="black"
    />
  </svg>
);

export default JoinQuizMasterModal;
