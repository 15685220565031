import { MdClose } from "react-icons/md";
import { Modal } from "react-responsive-modal";

const AlertModal = ({ isOpen, onClose, text }) => {
  return (
    <Modal
      focusTrapped={false}
      showCloseIcon={false}
      open={isOpen}
      onClose={onClose}
      classNames={{ modal: "custom-modal" }}
      center
    >
      <div className="pb-5 gap-5 relative flex flex-col items-center justify-center h-full w-full">
        <div className="flex flex-col items-center justify-center gap-1">
          <h2 className="relative px-3 py-1.5 bg-red-500 text-white text-lg font-medium text-center w-full">
            Alert!
            <button
              type="button"
              onClick={onClose}
              className={`bg-white rounded-lg close-icon z-40 absolute top-1 right-2 p-1.5`}
            >
              <MdClose color="black" size={20} />
            </button>
          </h2>

          {text && (
            <div className="px-5 space-y-3 py-1 mt-5 mb-1 text-center text-gray-600 w-full">
              {text.split("\n").map((line, index) => (
                <p
                  key={index}
                  className={index === 0 ? "font-semibold" : "font-medium"}
                >
                  {line}
                </p>
              ))}
            </div>
          )}
        </div>
        <div>
          <button onClick={onClose} className="btn btn-error text-white">
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AlertModal;
