import { lazy, Suspense, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import PrivateRoute from "./PrivateRoute";
import axios from "./axios";
import MoonLoader from "./components/MoonLoader";
import PageLayout from "./components/PageLayout";
import ScrollToTop from "./components/ScrollToTop";
import { config } from "./config";
import { useGoogleAnalytics } from "./hooks";
import { selectUser, setLogout } from "./redux/slices/userSlice";
import { getRefreshToken, removeJwtTokens, setJwtTokens } from "./utils";
import { findClassesByLevel, findLevelByClass } from "./utils/levels";
import { registerServiceWorker } from "./utils/serviceWorkerRegistration";

const LazyMedia = lazy(() => import("./pages/Media"));
const LazyTitleLegend = lazy(() => import("./pages/TitleLegend"));
const LazyOTP = lazy(() => import("./pages/Login/Otp"));
const LazyHome = lazy(() => import("./pages/HomeNew"));
const LazyUserInfo = lazy(() => import("./pages/Login/UserInfo"));
const LazyCompeteQuiz = lazy(() => import("./pages/Quizzes/Compete"));
const LazyCompeteQuizPlay = lazy(() => import("./pages/Quiz/QuizForm/Compete"));
const LazyStreakQuiz = lazy(() => import("./pages/Quizzes/Streak"));
const LazyStreakExplore = lazy(() => import("./pages/Quizzes/Explore"));
const LazyStreakChallengePlay = lazy(() =>
  import("./pages/Quiz/QuizForm/Streak")
);
const LazyStreakExplorePlay = lazy(() =>
  import("./pages/Quiz/QuizForm/Explore/")
);

const LazyQuizManagementSelection = lazy(() =>
  import("./pages/QuizManagement/SelectView")
);
const LazyQuizManagement = lazy(() => import("./pages/QuizManagement"));
const LazyUserManagement = lazy(() => import("./pages/UserManagement"));
const LazyQuizSubmissions = lazy(() => import("./pages/QuizSubmissions"));
const LazyQuizCreation = lazy(() =>
  import("./pages/QuizManagement/QuizCreation")
);

const LazyQuizPreview = lazy(() =>
  import("./pages/QuizManagement/QuizPreview")
);
const LazyQuestionManagement = lazy(() =>
  import("./pages/QuestionManagement/QuestionManagement")
);
const LazyQuizInfo = lazy(() => import("./pages/QuizInfo"));
const LazyPerformance = lazy(() => import("./pages/Performance"));
const LazyWinners = lazy(() => import("./pages/QuizWinners"));
const LazyQuizMasterPublicProfile = lazy(() =>
  import("./pages/UserPublicProfile/Profiles/QuizMasterPublicProfile")
);
const LazyStudentPublicProfile = lazy(() =>
  import("./pages/UserPublicProfile/Profiles/StudentPublicProfile")
);
const LazyLeaderboards = lazy(() => import("./pages/Leaderboards"));
const LazyHelp = lazy(() => import("./pages/Help"));
const LazyUserProfile = lazy(() => import("./pages/UserProfile"));
const LazyUserMathMasterProfile = lazy(() =>
  import("./pages/Games/MathMaster/pages/UserMathMasterProfile")
);
const LazyTransactions = lazy(() => import("./pages/Transactions"));
const LazyMathMaster = lazy(() => import("./pages/Games/MathMaster"));
const LazyPuzzleMania = lazy(() => import("./pages/Games/PuzzleMania"));
const LazyStreakStats = lazy(() => import("./pages/Quizzes/Stats/Performance"));
const LazyStreakDailyScores = lazy(() =>
  import("./pages/Quizzes/Stats/DailyScore")
);
const LazyStreakLeaderboards = lazy(() =>
  import("./pages/Quizzes/Stats/StreakLeaderboards")
);

const App = () => {
  useGoogleAnalytics();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(selectUser);

  const [state, setState] = useState("");
  const [grade, setGrade] = useState(
    findClassesByLevel(findLevelByClass(Number(user?.grade))).join(",") || ""
  );
  const [timespan, setTimespan] = useState("30");

  useEffect(() => {
    if (user?._id) {
      registerServiceWorker(user._id);
    }
  }, [user?._id]);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      window.deferredPrompt = e;
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (error?.response?.status === 401 && !originalRequest?._retry) {
        originalRequest._retry = true;
        try {
          const oldRefreshToken = getRefreshToken();
          if (oldRefreshToken) {
            const result = await axios.post(
              `${config.backendUrl}/api/user/refresh-token`,
              { refreshToken: oldRefreshToken }
            );
            if (result.data.success) {
              const tokensData = result.data.data;
              setJwtTokens(tokensData);
              originalRequest.headers.authorization = `Bearer ${tokensData.accessToken}`;
              return axios(originalRequest);
            } else {
              throw new Error();
            }
          } else {
            throw new Error();
          }
        } catch (err) {
          removeJwtTokens();
          dispatch(setLogout({}));
          navigate("/");
          toast.error("Your session has expired. Please login again");
          return Promise.reject(err);
        }
      }
      return Promise.reject(error);
    }
  );

  const toastOptions = {
    duration: 2000,
  };

  return (
    <ScrollToTop>
      <Suspense fallback={<MoonLoader />}>
        <Toaster toastOptions={toastOptions} />
        <PageLayout>
          <Routes>
            <Route path="/" element={<LazyHome />} />
            <Route
              path="/quiz/create"
              element={
                <PrivateRoute
                  roles={["quizmaster"]}
                  component={LazyQuizCreation}
                />
              }
            />
            <Route
              path="/quiz/:id/submissions"
              element={
                <PrivateRoute
                  roles={["quizmaster"]}
                  component={LazyQuizSubmissions}
                />
              }
            />
            <Route
              path="/quiz/:id/edit"
              element={
                <PrivateRoute
                  roles={["quizmaster"]}
                  component={LazyQuizCreation}
                />
              }
            />
            <Route
              path="/quiz/:id/preview"
              element={
                <PrivateRoute
                  roles={["quizmaster"]}
                  component={LazyQuizPreview}
                />
              }
            />
            <Route
              path="/quiz/manage/select"
              element={
                <PrivateRoute
                  roles={["quizmaster"]}
                  component={LazyQuizManagementSelection}
                />
              }
            />
            <Route
              path="/quizzes/compete"
              element={
                <PrivateRoute roles={["student"]} component={LazyCompeteQuiz} />
              }
            />
            <Route path="/quiz/:id" element={<LazyCompeteQuizPlay />} />
            <Route
              path="/quizzes/streak"
              element={
                <PrivateRoute roles={["student"]} component={LazyStreakQuiz} />
              }
            />

            <Route
              path="/quizzes/streak/explore"
              element={
                <PrivateRoute
                  roles={["student"]}
                  component={LazyStreakExplore}
                />
              }
            />
            <Route
              path="/quiz/streak/challenge"
              element={
                <PrivateRoute
                  roles={["student"]}
                  component={LazyStreakChallengePlay}
                />
              }
            />
            <Route
              path="/quiz/streak/explore"
              element={
                <PrivateRoute
                  roles={["student"]}
                  component={LazyStreakExplorePlay}
                />
              }
            />
            <Route
              path="/quizzes/streak/stats"
              element={
                <PrivateRoute
                  roles={["student", "quizmaster"]}
                  component={LazyStreakStats}
                />
              }
            />
            <Route
              path="/quizzes/streak/daily-score"
              element={
                <PrivateRoute
                  roles={["student", "quizmaster"]}
                  component={LazyStreakDailyScores}
                />
              }
            />
            <Route
              path="/quizzes/streak/leaderboards"
              element={
                <PrivateRoute
                  roles={["student", "quizmaster"]}
                  component={LazyStreakLeaderboards}
                  componentProps={{
                    setGrade,
                    setState,
                    setTimespan,
                    state,
                    grade,
                    timespan,
                  }}
                />
              }
            />
            <Route path="/quizzes/:id" element={<LazyQuizInfo />} />
            <Route path="/quiz/:id/winners" element={<LazyWinners />} />
            <Route
              path="/user-profile"
              element={
                <PrivateRoute
                  roles={["student", "quizmaster"]}
                  component={LazyUserProfile}
                />
              }
            />
            <Route
              path="/user-profile/:id"
              element={<LazyStudentPublicProfile />}
            />
            <Route
              path="/quizmaster-profile/:id"
              element={<LazyQuizMasterPublicProfile />}
            />
            <Route
              path="/user-mathmaster-profile/:id"
              element={<LazyUserMathMasterProfile />}
            />
            <Route path="/otp" element={<LazyOTP />} />
            <Route
              path="/titles"
              element={
                <PrivateRoute
                  roles={["student", "quizmaster"]}
                  component={LazyTitleLegend}
                />
              }
            />
            <Route path="/newuserinfo" element={<LazyUserInfo />} />
            <Route
              path="games/maths/mathmaster/*"
              element={
                <PrivateRoute
                  roles={["student", "quizmaster"]}
                  component={LazyMathMaster}
                />
              }
            />
            <Route
              path="games/puzzles/puzzlemania/*"
              element={
                <PrivateRoute
                  roles={["student", "quizmaster"]}
                  component={LazyPuzzleMania}
                />
              }
            />
            <Route
              path="/performance"
              element={
                <PrivateRoute roles={["student"]} component={LazyPerformance} />
              }
            />
            <Route
              path="/quiz-management"
              element={
                <PrivateRoute
                  roles={["quizmaster"]}
                  component={LazyQuizManagement}
                />
              }
            />
            <Route
              path="/user-management"
              element={
                <PrivateRoute
                  roles={["quizmaster"]}
                  component={LazyUserManagement}
                />
              }
            />
            <Route
              path="/question-management"
              element={
                <PrivateRoute
                  roles={["quizmaster"]}
                  component={LazyQuestionManagement}
                />
              }
            />
            <Route
              path="/leaderboards"
              element={
                <LazyLeaderboards
                  setGrade={setGrade}
                  setState={setState}
                  setTimespan={setTimespan}
                  state={state}
                  grade={grade}
                  timespan={timespan}
                />
              }
            />
            <Route path="/help" element={<LazyHelp />} />
            <Route path="/media" element={<LazyMedia />} />
            <Route path="/transactions" element={<LazyTransactions />} />
          </Routes>
        </PageLayout>
      </Suspense>
    </ScrollToTop>
  );
};

export default App;
