import axios from "../../axios";

const getPresignedUrl = async (data) => {
  const response = await axios({
    method: "post",
    url: `/api/file/upload-url`,
    data,
  });

  return response.data;
};

const getPresignedUrlForDelete = async (data) => {
  const response = await axios({
    method: "post",
    url: `/api/file/delete-url`,
    data,
  });

  return response.data;
};

const deleteFileByUrl = async (data) => {
  const response = await axios({
    method: "delete",
    url: `/api/file/`,
    data,
  });

  return response.data;
};

export { deleteFileByUrl, getPresignedUrl, getPresignedUrlForDelete };

