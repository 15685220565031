import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import competeQuizIcon from "../../assets/images/competeQuiz.png";
import exploreIcon from "../../assets/images/explore.png";
import playGameIcon from "../../assets/images/games/mathmaster/playGame.png";
import quizmasterIcon from "../../assets/images/quizManage.png";
import streakIcon from "../../assets/images/streak.png";
import { selectUser } from "../../redux/slices/userSlice";
import Bubbles from "../AnimatedScenes/Bubbles/Bubbles";

const QuizTypeSelection = ({ onClose }) => {
  const user = useSelector(selectUser);
  return (
    <div className="isolate bg-gradient-to-t from-purple-200 to-[#FFFDD0] shadow-lg ring-1 ring-black/5 rounded-md flex flex-col items-center justify-center">
      <Bubbles />

      <div className="z-[3] pb-5 mt-8 mb-2 px-6 grid grid-cols-2 w-full gap-x-12 gap-y-8">
        {user?.role === "quizmaster" ? (
          <Link
            onClick={onClose}
            to={"/quiz/manage/select"}
            className="flex flex-col items-center justify-center"
          >
            <img
              src={quizmasterIcon}
              className="z-[3] h-[66px] animate-scale-fast"
              alt="quizmaster"
            />
            <p className="text-center mt-2 rounded-full font-semibold text-sm">
              Manage Quiz
            </p>
          </Link>
        ) : (
          <>
            <Link
              onClick={onClose}
              to={"/quizzes/compete"}
              className="flex flex-col items-center justify-center"
            >
              <img
                src={competeQuizIcon}
                className="z-[3] h-[66px] animate-scale-fast"
                alt="performance"
              />
              <p className="text-center mt-2 rounded-full font-semibold text-sm">
                Compete Quiz
              </p>
            </Link>

            <Link
              onClick={onClose}
              to={"/quizzes/streak"}
              className="flex flex-col items-center justify-center"
            >
              <img
                src={streakIcon}
                className="z-[3] animate-scale-fast-2 h-[61px]"
                alt="streak"
              />
              <p className="text-center mt-2 rounded-full font-semibold text-sm">
                Streak Challenge
              </p>
            </Link>
          </>
        )}
        <Link
          onClick={onClose}
          to={"/quizzes/streak/explore"}
          className="flex flex-col items-center justify-center"
        >
          <img src={exploreIcon} className="z-[3] h-[61px]" alt="explore" />
          <p className="mt-2 text-sm text-center font-semibold rounded-full">
            Explore Quiz
          </p>
        </Link>
        <Link
          onClick={onClose}
          to={"/games/maths/mathmaster"}
          className="flex flex-col items-center justify-center"
        >
          <img src={playGameIcon} className="h-14" alt="performance" />
          <p className="text-center mt-2 rounded-full font-semibold text-sm">
            MathMaster
          </p>
        </Link>
      </div>
    </div>
  );
};

export default QuizTypeSelection;
