import { memo, useState } from "react";
import { FaHome } from "react-icons/fa";
import { MdLeaderboard } from "react-icons/md";
import { useSelector } from "react-redux";
import { uid } from "react-uid";
import defaultAvatar from "../../assets/images/defaultAvatar.jpg";
import quizIcon from "../../assets/images/quizBulb.png";
import { selectUser } from "../../redux/slices/userSlice";
import LoginModal from "../Modals/LoginModal/LoginModal";
import BottombarLink from "./BottombarLink";
const Bottombar = () => {
  const user = useSelector(selectUser);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showQuizTypeModal, setShowQuizTypeModal] = useState(false);
  const iconColor = "white";

  const links = [
    {
      path: "/",
      text: "Home",
      icon: <FaHome size={19} color={iconColor} />,
      loginRequired: false,
    },

    {
      path: "/quizzes/compete",
      text: "Quiz",
      icon: (
        <img
          className="cursor-pointer h-[16px]"
          src={quizIcon}
          alt="user-avatar"
        />
      ),
      loginRequired: true,
      showLoginModal: true,
    },

    {
      path: "/leaderboards",
      text: "Leaderboard",
      icon: <MdLeaderboard color={iconColor} size={19} />,
      loginRequired: false,
    },
    {
      path: "/user-profile",
      text: "Profile",
      icon: (
        <img
          className="rounded-full cursor-pointer h-[20px] w-auto"
          src={user?.profileImage ?? defaultAvatar}
          alt="user-avatar"
        />
      ),
      loginRequired: true,
      showLoginModal: false,
    },
  ];

  return (
    <footer className="fixed z-50 left-0 right-0 bottom-0 h-[80px] md:h-[90px] py-4 bg-[#760A98] flex justify-around items-center px-3 w-full text-white">
      {links.map((link) =>
        !user?._id && link.loginRequired && !link.showLoginModal ? null : (
          <BottombarLink
            link={link}
            key={uid(link)}
            setShowLoginModal={setShowLoginModal}
            setShowQuizTypeModal={setShowQuizTypeModal}
          />
        )
      )}
      <LoginModal
        isOpen={showLoginModal}
        onClose={() => setShowLoginModal(false)}
        setShowLoginModal={setShowLoginModal}
        setShowQuizTypeModal={setShowQuizTypeModal}
      />

    </footer>
  );
};

export default memo(Bottombar);
