import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { selectUser } from "./redux/slices/userSlice";

const PrivateRoute = ({ roles = [], component: Component, componentProps }) => {
  const user = useSelector(selectUser);
  const role = user.role ?? "student";
  const token = localStorage.getItem("CHERRIQUIZ_ACCESS_TOKEN");
  return token && roles.includes(role) ? (
    <Component {...componentProps} />
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateRoute;
