import { useEffect, useState } from "react";
import Select from "react-select";
import "./ReactSelect.css";

const ReactSelect = ({
  data,
  setValue,
  defaultValue,
  isSearchable,
  placeholder,
  isDisabled,
}) => {
  const customStyles = {
    menu: (provided) => ({
      ...provided,
      position: "absolute",
      width: "100%",
      overflowY: "auto",
    }),
    menuPortal: (base) => ({ ...base, zIndex: 100000 }),
    option: (provided) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      textAlign: "center",
      width: "100%",
      fontFamily: "font-family: 'Roboto', sans-serif;",
      padding: 10,
      fontSize: "14px",
    }),
    control: () => ({
      border: "1px solid #c0c0c0",
      background: "#fff",
      display: "flex",
      borderRadius: "5px",
      cursor: "pointer",
      paddingTop: "2px",
      paddingBottom: "2px",
      fontSize: "13px",
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      return { ...provided, opacity, transition };
    },
  };

  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (defaultValue !== undefined) {
      const defaultOption = data?.find(
        (option) => option.value === defaultValue
      );
      setSelectedOption(defaultOption);
    }
  }, [defaultValue, data]);

  const handleSelect = (selectedOption) => {
    setSelectedOption(selectedOption);
    setValue(selectedOption.value);
  };

  return (
    <div className="react-select">
      <Select
        options={data}
        placeholder={placeholder || "Select..."}
        value={selectedOption}
        styles={customStyles}
        onChange={handleSelect}
        isSearchable={isSearchable}
        menuPortalTarget={document.body}
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default ReactSelect;
