import axios from "axios";
import { config } from "../config";
import { getAccessToken } from "../utils";

const instance = axios.create({
  baseURL: config.backendUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  async (config) => {
    config.headers.authorization = `Bearer ${getAccessToken()}`;
    return config;
  },
  (error) => Promise.reject(error)
);

export default instance;
