const {
  REACT_APP_DEFAULT_QUIZMASTER,
  REACT_APP_RAZORPAY_API_KEY,
  REACT_APP_CHERRIQUIZ_WHATSAPP_NUMBER,
  REACT_APP_CHERRIQUIZ_EMAIL,
  REACT_APP_PUBLIC_VAPID_KEY,
  REACT_APP_BACKEND_URL,
  REACT_APP_FRONTEND_URL,
  REACT_APP_GENERAL_CATEGORY_ID,
  NODE_ENV,
} = process.env;

module.exports.config = {
  defaultQuizmaster: REACT_APP_DEFAULT_QUIZMASTER,
  razorpayApikey: REACT_APP_RAZORPAY_API_KEY,
  publicVapidKey: REACT_APP_PUBLIC_VAPID_KEY,
  cherriquizWhatsappNumber: REACT_APP_CHERRIQUIZ_WHATSAPP_NUMBER,
  cherriquizEmail: REACT_APP_CHERRIQUIZ_EMAIL,
  backendUrl: REACT_APP_BACKEND_URL,
  frontendUrl: REACT_APP_FRONTEND_URL,
  nodeEnv: NODE_ENV,
  generalCategoryId: REACT_APP_GENERAL_CATEGORY_ID,
};
