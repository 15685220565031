import axios from "../../axios";

const updateProfile = async (data) => {
  const response = await axios({
    method: "put",
    url: "/api/user/profile",
    data,
  });

  return response.data;
};

const getProfile = async () => {
  const response = await axios({
    method: "get",
    url: "/api/user/profile",
  });
  return response.data;
};

const getUserPublicProfile = async (id, visitorId) => {
  const response = await axios({
    method: "get",
    url: `/api/user/${id}/profile`,
    params: {
      visitorId,
    },
  });
  return response.data;
};

const getUserQuizHistory = async (params) => {
  const response = await axios({
    method: "get",
    url: `/api/user/history`,
    params,
  });
  return response.data;
};

const checkClassChangeEligibility = async (data) => {
  const response = await axios({
    method: "get",
    url: `/api/user/classchange-eligible`,
    data,
  });

  return response.data;
};

const getUsers = async (params) => {
  const response = await axios({
    method: "get",
    url: `/api/user`,
    params,
  });

  return response.data;
};

const likeUser = async (userId) => {
  const response = await axios({
    method: "post",
    url: `/api/user/${userId}/like`,
  });
  return response.data;
};

const unlikeUser = async (userId) => {
  const response = await axios({
    method: "post",
    url: `/api/user/${userId}/unlike`,
  });
  return response.data;
};

const getLikes = async (userId) => {
  const response = await axios({
    method: "get",
    url: `/api/user/${userId}/like`,
  });
  return response.data;
};

const getHouseholdStudents = async (householdId) => {
  const response = await axios({
    method: "get",
    url: `/api/household/${householdId}/students`,
  });
  return response.data;
};

const getJoinees = async (params) => {
  const response = await axios({
    method: "get",
    url: `/api/joinee`,
    params,
  });
  return response.data;
};

const acceptJoinees = async (data) => {
  const response = await axios({
    method: "post",
    url: `/api/joinee/accept`,
    data,
  });
  return response.data;
};

const rejectJoinees = async (data) => {
  const response = await axios({
    method: "post",
    url: `/api/joinee/reject`,
    data,
  });
  return response.data;
};

const deleteJoinees = async (data) => {
  const response = await axios({
    method: "delete",
    url: `/api/joinee`,
    data,
  });
  return response.data;
};

const joinQuizmaster = async (code) => {
  const response = await axios({
    method: "post",
    url: `/api/joinee/join/${code}`,
  });
  return response.data;
};

const updateProfileImage = async (awsUrl) => {
  const response = await axios({
    method: "put",
    url: `/api/user/profile-image`,
    data: { awsUrl },
  });
  return response.data;
};

const deleteProfileImage = async () => {
  const response = await axios({
    method: "delete",
    url: `/api/user/profile-image`,
  });
  return response.data;
};

const getUserPracticeStats = async () => {
  const response = await axios({
    method: "get",
    url: `/api/user/stats/practice`,
  });

  return response.data;
};

const getUserCompeteStats = async () => {
  const response = await axios({
    method: "get",
    url: `/api/user/stats/compete`,
  });

  return response.data;
};

const getQuizMastersJoined = async () => {
  const response = await axios({
    method: "get",
    url: `/api/user/quizmaster`,
  });
  return response.data;
};

const changeAutoAcceptStatus = async (data) => {
  const response = await axios({
    method: "post",
    url: `/api/user/autoaccept`,
    data,
  });
  return response.data;
};

const getCurrentQuizmaster = async (data) => {
  const response = await axios({
    method: "get",
    url: `/api/user/quizmaster/current`,
    data,
  });
  return response.data;
};

const updateCurrentQuizmaster = async (data) => {
  const response = await axios({
    method: "put",
    url: `/api/user/quizmaster/current`,
    data,
  });
  return response.data;
};

const getAllQuizmasters = async (params) => {
  const response = await axios({
    method: "get",
    url: `/api/admin/quizmaster`,
    params,
  });
  return response.data;
};

const switchAccount = async (data) => {
  const response = await axios({
    method: "post",
    url: `/api/user/switchaccount`,
    data,
  });
  return response.data;
};

const addAcademicSubcategory = async (data) => {
  const response = await axios({
    method: "post",
    url: `/api/user/academic-subcategory`,
    data,
  });
  return response.data;
};

const getAcademicSubcategories = async (data) => {
  const response = await axios({
    method: "get",
    url: `/api/user/academic-subcategory`,
    data,
  });
  return response.data;
};

export {
  acceptJoinees,
  addAcademicSubcategory,
  changeAutoAcceptStatus,
  checkClassChangeEligibility,
  deleteJoinees,
  deleteProfileImage,
  getAcademicSubcategories,
  getAllQuizmasters,
  getCurrentQuizmaster,
  getHouseholdStudents,
  getJoinees,
  getLikes,
  getProfile,
  getQuizMastersJoined,
  getUserCompeteStats,
  getUserPracticeStats,
  getUserPublicProfile,
  getUserQuizHistory,
  getUsers,
  joinQuizmaster,
  likeUser,
  rejectJoinees,
  switchAccount,
  unlikeUser,
  updateCurrentQuizmaster,
  updateProfile,
  updateProfileImage
};

