import { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  checkUserExists,
  loginWithOtp,
  loginWithPassword,
  sendOtp,
} from "../../apis/user/auth";
import chikkuMobile from '../../assets/images/chikkuWithMobile.png';
import greenPlay from "../../assets/images/greenPlay.png";
import cherriQuizBanner from "../../assets/images/preview.jpg";
import ShareButton from "../../components/Buttons/ShareButton";
import { HtmlInput } from "../../components/InputFields";
import QuizTypeSelection from "../../components/QuizTypeSelection/QuizTypeSelection";
import TitleBetweenLine from "../../components/TitleBetweenLine";
import { config } from "../../config";
import { selectUser, setUser } from "../../redux/slices/userSlice";
import { setJwtTokens } from "../../utils";
import { useAuthContext } from "../Login/contexts/AuthContext";
import QuizMasterSelect from "./QuizMasterSelect/QuizMasterSelect";
const HomeLogin = ({
  inputFlexDir,
  setShowLoginModal,
  setShowQuizTypeModal,
  isHome,
}) => {
  const [loading, setLoading] = useState(false);

  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { phone, setPhone } = useAuthContext();
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [phoneExists, setPhoneExists] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);
  const [otpSent, setOtpSent] = useState(false);
  const { otpSecret, setOtpSecret } = useAuthContext();

  const handlePhoneChange = (e) => {
    setPhone(e.target.value.trim());
    setPassword("");
    setPhoneExists(false);
  };

  const handleLoginWithPassword = async () => {
    const loginInfo = { phone, password };
    const response = await loginWithPassword(loginInfo);

    if (response.status === "success") {
      dispatch(setUser(response.profile));
      setJwtTokens(response.tokenData);
      if (setShowLoginModal) setShowLoginModal(false);
    } else {
      toast.error("Password Incorrect");
    }
  };

  const handleSendOtp = async () => {
    const response = await sendOtp({ phone, appSignature: "CHERRILEARN" });
    if (response.status === "success") {
      setOtpSecret(response.data);
      setOtpSent(true);
    }
  };

  const handleResendOtp = async () => {
    if (resendDisabled)
      return toast.error(`Wait ${resendTimer} Seconds to Resend OTP!`);
    const response = await sendOtp({ phone, appSignature: "CHERRILEARN" });
    if (response.status === "success") {
      setOtpSecret(response.data);
      setResendDisabled(true);
      setResendTimer(60);
      const interval = setInterval(() => {
        setResendTimer((prev) => {
          if (prev === 0) {
            setResendDisabled(false);
            clearInterval(interval);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
  };

  const handleVerifyOtp = async () => {
    if (otp?.trim().length !== 6) return toast.error("Please Enter Valid OTP");
    const data = { otp, otpSecret, phone };
    const response = await loginWithOtp(data);
    if (response.status === "success") {
      dispatch(setUser(response.profile));
      setJwtTokens(response.tokenData);
      setOtpSecret("");
      if (setShowLoginModal) {
        setShowLoginModal(false);
      }
    } else {
      toast.error(response.message);
    }
  };

  const handlePhoneClick = async () => {
    if (phone.length !== 10) {
      return toast.error("Phone Number Must Be 10 Digits Long");
    }
    const response = await checkUserExists({
      phone,
      appSignature: "cherri-learn",
    });

    if (response.status === "success" && response.data?.userExists) {
      setPhoneExists(true);
    } else {
      setOtpSecret(response.data.otpSecret);
      navigate("/otp");
      if (setShowLoginModal) setShowLoginModal(false);
    }
  };

  return (
    <>
      {!user?._id && <div
        className={`-mt-4 ${user?.role === "quizmaster"
          ? "h-[160px] md:h-[280px]"
          : "h-[220px] md:h-[230px]"
          } w-[60%] md:w-auto`}
      >
        <img
          src={
            chikkuMobile
          }
          className="w-full h-full object-contain"
          alt="cherri-chikku"
        />
      </div>}
      {!user?._id && !isHome && (
        <p className="text-2xl md:text-3xl text-center md:text-left font-medium">
          Signup and Start Playing!
        </p>
      )}
      {isHome ? (
        <div className="flex flex-col items-center justify-center gap-5 w-full mb-1">
          {!user?._id || user?.role === "quizmaster" ? (
            <div className="flex items-center justify-center  gap-5 relative w-full">
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  if (!user._id) setShowLoginModal(true);
                  else {
                    setShowQuizTypeModal(true);
                  }
                }}
                to="/quizzes"
                className="w-fit bg-white rounded-md flex justify-between items-center gap-2 font-medium outline-none px-4 py-1 border border-[3px] border-[#7CB342] shadow-md"
              >
                <p className="font-semibold text-md">
                  {user?.role === "quizmaster" ? "Explore" : "Play Now"}
                </p>
                <img
                  className="h-10 animate-pulse-fast"
                  src={greenPlay}
                  alt=""
                />
              </Link>
            </div>
          ) : (
            <div className="-mt-6 flex flex-col items-center justify-center gap-2">
              <QuizMasterSelect />
              <QuizTypeSelection />
            </div>
          )}
        </div>
      ) : (
        <div
          className={`flex ${inputFlexDir === "col" ? "flex-col" : ""
            } items-start justify-between w-full max-w-md gap-4`}
        >
          <div className="flex-grow w-full">
            <HtmlInput
              placeholder="Phone No."
              value={phone}
              onChange={handlePhoneChange}
              type="phone"
              pattern="[0-9]*"
              inputMode="numeric"
              className="w-full"
            />
          </div>
          <button
            onClick={handlePhoneClick}
            className={`${password ? (inputFlexDir === "col" ? "hidden" : "invisible") : ""
              } bg-green-500 hover:bg-green-600 text-sm md:text-base font-medium outline-none  py-2.5 text-white rounded-full border-none w-36`}
          >
            Continue
          </button>
        </div>
      )}
      {phoneExists && !user?._id && (
        <>
          <div
            className={`flex ${inputFlexDir === "col" ? "flex-col" : ""
              } items-start justify-between w-full max-w-md gap-4`}
          >
            <div className="flex-grow w-full">
              <HtmlInput
                placeholder="Enter Password"
                value={password}
                onChange={(e) => setPassword(e.target.value.trim())}
                type="password"
                className="w-full"
              />
            </div>
            <button
              onClick={handleLoginWithPassword}
              className={`${password ? "" : inputFlexDir === "col" ? "hidden" : "invisible"
                } bg-green-500 hover:bg-green-600 text-sm md:text-base font-medium outline-none py-2.5 text-white rounded-full border-none w-36`}
            >
              Submit
            </button>
          </div>
          <TitleBetweenLine title={"OR"} />
          <div
            className={`flex ${inputFlexDir === "col" ? "flex-col" : ""
              } items-start justify-between w-full max-w-md gap-4`}
          >
            {otpSent && (
              <div className="flex-grow w-full ">
                <HtmlInput
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value.trim())}
                  type="text"
                  className="w-full"
                />
              </div>
            )}
            <button
              onClick={otpSent ? handleVerifyOtp : handleSendOtp}
              className={`text-sm md:text-base font-medium outline-none text-white rounded-full border-none  bg-orange-500 hover:bg-orange-600 ${otpSent ? "w-36 py-2.5" : "mx-auto w-fit px-8 py-3"
                }`}
            >
              {otpSent ? "Submit" : "Login via OTP"}
            </button>
          </div>
          {otpSent && (
            <p
              onClick={handleResendOtp}
              className={`-mt-2 font-medium underline underline-offset-2 text-md text-blue-600 cursor-pointer ${resendDisabled ? "text-gray-500" : ""
                }`}
            >
              {resendDisabled ? `Resend OTP in ${resendTimer}s` : "Resend OTP"}
            </p>
          )}
        </>
      )}

      {isHome && (
        <div className="-my-3">
          <ShareButton
            imageClassName="cursor-pointer w-12 h-12 rounded-full border border-[#760A98] bg-white border-[2px]"
            fileName={`CherriQuiz.png`}
            file={cherriQuizBanner}
            title={`Join the Ultimate Quiz 💡 Adventure at CherriQuiz 🥳 and win exciting Badges and Rewards 😊`}
            text={`Join the Ultimate Quiz 💡 Adventure at CherriQuiz 🥳 and win exciting Badges and Rewards 😊`}
            url={config.frontendUrl}
            setLoading={setLoading}
          />
        </div>
      )}
    </>
  );
};

export default HomeLogin;
