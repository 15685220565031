import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useRef, useState } from "react";
import { BiMenu, BiPlus } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  getCurrentQuizmaster,
  getQuizMastersJoined,
  updateCurrentQuizmaster,
} from "../../../apis/user/user";
import quizmasterDefaultAvatar from "../../../assets/images/quizManage.png";
import { selectUser, setUser } from "../../../redux/slices/userSlice";
import JoinQuizMasterModal from "./JoinQuizMasterModal";
const QuizMasterSelect = () => {
  const user = useSelector(selectUser);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();

  const { data: quizMastersData, refetch } = useQuery({
    queryKey: ["quizmastersJoined", user?._id],
    queryFn: getQuizMastersJoined,
    enabled: !!user?._id,
  });

  const { data: currentQuizmaster } = useQuery({
    queryKey: ["currentQuizmaster", user?._id],
    queryFn: getCurrentQuizmaster,
    enabled: !!user?._id,
  });

  const mutation = useMutation({
    mutationFn: () =>
      updateCurrentQuizmaster({ newQuizmasterId: selectedOption.value }),
    onSuccess: () => {
      dispatch(setUser({ ...user, currentQuizmaster: selectedOption.value }));
    },
  });

  const formatQuizMasterData = (data) => {
    if (!data) return [];

    const joinedQuizMasters =
      data?.joined?.map(({ _id, name, profileImage }) => ({
        value: _id,
        label: name,
        image: profileImage,
      })) || [];

    const defaultQuizMaster = data.default
      ? {
          value: data.default._id,
          label: data.default.name,
          image: data.default.profileImage,
        }
      : null;

    const addNewOption = {
      value: "add-new",
      label: "Add Quiz Master",
    };

    return defaultQuizMaster
      ? [defaultQuizMaster, ...joinedQuizMasters, addNewOption]
      : [...joinedQuizMasters, addNewOption];
  };

  const options = formatQuizMasterData(quizMastersData?.data);

  useEffect(() => {
    if (currentQuizmaster && currentQuizmaster.data) {
      setSelectedOption({
        value: currentQuizmaster.data._id,
        label: currentQuizmaster.data.name,
        image: currentQuizmaster.data.profileImage,
      });
    }
  }, [currentQuizmaster]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (option) => {
    if (option.value === "add-new") {
      setIsModalOpen(true);
    } else {
      setSelectedOption(option);
      mutation.mutate(option.value);
    }
    setIsOpen(false);
  };

  return (
    <div className="shadow-lg rounded-md relative w-full" ref={dropdownRef}>
      <div
        className="text-white rounded-md px-5 py-2.5 flex justify-between gap-4 items-center cursor-pointer bg-[#7E57C2]"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center justify-center gap-4">
          {" "}
          <BiMenu className="w-5 h-5 mr-2" />
          <span className="font-semibold">
            {selectedOption ? selectedOption.label : "Select Team"}
          </span>
        </div>

        {selectedOption && selectedOption.image && (
          <img
            src={selectedOption.image}
            className="h-8 w-8 rounded-full"
            alt={selectedOption.label}
          />
        )}
      </div>
      {isOpen && (
        <ul className="z-40 absolute w-full mt-1 bg-white border border-gray-300 rounded-xl shadow-lg max-h-60 overflow-auto">
          {options.map((option) => (
            <li
              key={option.value}
              className="border-b px-2 py-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => handleSelect(option)}
            >
              {option.value === "add-new" ? (
                <div className="flex items-center text-blue-600 font-medium">
                  {option.label}
                  <BiPlus className="w-4 h-4 mr-2" />
                </div>
              ) : (
                <div className="flex items-center justify-start gap-4">
                  <Link to={`quizmaster-profile/${option.value}`}>
                    <img
                      src={option?.image || quizmasterDefaultAvatar}
                      className="h-7 w-7 rounded-full"
                      alt={option.label}
                    />
                  </Link>
                  <p className="font-medium">{option.label}</p>
                </div>
              )}
            </li>
          ))}
        </ul>
      )}
      <JoinQuizMasterModal
        refetch={refetch}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default QuizMasterSelect;
