import { useState } from "react";
import AlertModal from "../components/Modals/AlertModal";

export const useAlertModal = () => {
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [alertText, setAlertText] = useState("");

  const showAlert = (text) => {
    setAlertText(text);
    setShowAlertModal(true);
  };

  const closeAlert = () => {
    setShowAlertModal(false);
  };

  const AlertModalComponent = (
    <AlertModal isOpen={showAlertModal} text={alertText} onClose={closeAlert} />
  );

  return { showAlert, AlertModalComponent };
};
