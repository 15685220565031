import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { checkUserActive } from "../../apis/user/auth";
import { useAlertModal } from "../../hooks";
import { selectUser } from "../../redux/slices/userSlice";
const Navbar = () => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const { showAlert, AlertModalComponent } = useAlertModal();
  const handleManageQuizClick = async (e) => {
    e.preventDefault();

    try {
      const { data } = await checkUserActive();

      if (!data) {
        return showAlert("Action Not Allowed,\n Your Profile Is Inactive!");
      }

      navigate("/quiz/manage/select");
    } catch (error) {
      console.error("Error checking user status:", error);
    }
  };

  return (
    <>
      <div className="w-full relative py-1 h-[55px] md:h-[80px] z-40 border-b border-[#BDBDBD]">
        <div className="flex-wrap m-auto w-full h-full flex justify-center vsm:justify-between items-center pl-3 md:pl-6">
          <Link to="/" className="">
            <img
              src="/logo.png"
              alt="logo"
              className="h-[45px] md:h-[60px] cursor-pointer z-40"
            />
          </Link>
          <div className="pr-5 flex items-center justify-center text-sm ">
            {user?._id && (
              <Link
                className="text-blue-700 font-medium border-r border-[#BDBDBD] px-3"
                type="button"
                onClick={
                  user.role === "quizmaster" ? handleManageQuizClick : null
                }
                to={
                  user.role === "quizmaster"
                    ? "/quiz/manage/select"
                    : "/performance"
                }
              >
                {user.role === "quizmaster" ? "Manage Quiz" : "Performance"}
              </Link>
            )}

            <Link
              className="text-blue-700 font-medium border-r border-[#BDBDBD] px-3"
              type="button"
              to={"/media"}
            >
              Media
            </Link>

            <Link
              className="text-blue-700 font-medium pl-3"
              type="button"
              to={"/help"}
            >
              Help
            </Link>
          </div>
        </div>
      </div>
      {AlertModalComponent}
    </>
  );
};

export default Navbar;
