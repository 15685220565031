const findLevelByClass = (grade) => {
  return [1, 2, 3].includes(grade)
    ? "Kids"
    : [4, 5, 6, 7].includes(grade)
    ? "Junior"
    : "Senior";
};

const findClassesByLevel = (levels) => {
  let mergedClasses = [];

  if (levels.includes("Kids")) {
    mergedClasses = mergedClasses.concat([1, 2, 3]);
  }
  if (levels.includes("Junior")) {
    mergedClasses = mergedClasses.concat([4, 5, 6, 7]);
  }
  if (levels.includes("Senior")) {
    mergedClasses = mergedClasses.concat([8, 9, 10, 11, 12, 13, 14, 15]);
  }

  return mergedClasses;
};

module.exports = {
  findLevelByClass,
  findClassesByLevel,
};
